/*
 * 店铺设置接口
 * */
import request from '@/utils/request'

/*
 * 获取店铺信息
 * */
export function getStoreInfo() {
  return request({
    url: '/work/shop/get',
    method: 'get',
  })
}

/*
 * 更新店铺信息
 * */
export function saveStoreInfo(data) {
  return request({
    url: '/work/shop/update',
    method: 'post',
    data,
  })
}

/*
 * 获取云喇叭状态
 * */
export function getVoiceCloudState(params) {
  return request({
    url: '/work/shop/getVoiceCloudState',
    method: 'get',
    params
  })
}